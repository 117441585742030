<template>
  <div class="farm">
    <div class="swipe" v-if="list.length">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false" vertical height="0.4rem">
        <van-swipe-item v-for="(item, index) in list" :key="index">{{ item }}</van-swipe-item>
      </van-swipe>
    </div>
    <div class="tree"></div>
    <div class="circle"></div>
    <div class="stars"></div>
    <div class="desc"></div>
    <a :href="buttonLink">
      <div class="button"></div>
      <div
        id="download-btn"
        @click="handleClick('#download-btn', '固定button')"
        class="button"
        :data-clipboard-text="clipboardText"
      >
        领取水果
      </div>
    </a>
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>
<script>
  import IntroModal from '@/components/Modal/IntroModal/index.vue';
  import Download from '@/utils/download';
  import Clipboard from 'clipboard';

  export default {
    components: { IntroModal },
    props: {},
    name: 'farm',
    data() {
      return {
        list: [],
        showIntro: false,
        buttonLink: 'javascript:void(0);',
        clipboardText: this.$route.query.invite_code ? `邀请码【${this.$route.query.invite_code}】` : '',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      if (this.$platform.Android && !this.$platform.WeiXin) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = encodeURIComponent('https://play.rd.kaixinyf.cn/#/game/farm?fullScreen=true');
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = encodeURIComponent(`https://play.pub.kaixinyf.cn/#/game/farm?fullScreen=true`);
        } else if (process.env.NODE_ENV === 'production') {
          web_url = encodeURIComponent(`https://play.kaixinyf.cn/#/game/farm?fullScreen=true`);
        }

        this.buttonLink = `${SCHEME}://web?web_url=${web_url}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
      this.list = [
        '栖居在深山，已经成功收获6棵果树',
        '喵喵打野，已经成功收获6棵果树',
        '简单，已经成功收获5棵果树',
        '醉了好多年，已经成功收获5棵果树',
        '有趣无聊事, 已经成功收获2棵果树',
      ];
    },
    methods: {
      handleClick(element) {
        try {
          let jump = () => {
            let url;

            if (this.$platform.Android && this.$platform.WeiXin) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if (this.$platform.Android && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS) {
              url = Download.downLoad('share_ios');
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };
          const clipboard = new Clipboard(element);
          clipboard.on('success', (e) => {
            console.log(`success`);
            e.clearSelection();
            jump();
          });
          clipboard.on('error', () => {
            jump();
          });
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
      copyDownload(element, element_name, pageTitle, remarks) {
        try {
          const jump = () => {
            let url = '';

            if (this.$platform.Android && this.$platform.WeiXin) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if (this.$platform.Android) {
              url = Download.downLoad('fission');
            } else if (this.$platform.iOS) {
              url = Download.downLoad('share_ios');
            } else {
              // url = Download.downLoad('fission', { mode: 'divide' });
              url = Download.downLoad('fission');
            }

            window.location.href = url;
          };

          this.$toast({
            message: '亲~正在为你拉起下载页',
            duration: 5000,
            overlay: false,
          });
          // // 使用友盟U-link
          // if (this.umLink.isUmLinkInitSuccess) {
          //   return;
          // }

          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
    },
  };
</script>
<style scoped lang="less">
  .farm {
    position: relative;
    height: 100vh;
    background: url('~@/assets/images/farm/main-bg.png') no-repeat center center;
    background-size: cover;
  }
  .swipe {
    position: absolute;
    top: 2.38rem;
    left: 1.65rem;
    width: 4.2rem;
    height: 0.4rem;
    background: rgba(0, 31, 72, 0.1);
    border-radius: 0.28rem;
  }
  .my-swipe {
    width: 4.2rem;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
    font-size: 0.24rem;
    color: #ffffff;
  }
  .tree {
    position: absolute;
    top: 3.54rem;
    left: 0.34rem;
    width: 6.82rem;
    height: 7.78rem;
    background: url('~@/assets/images/farm/tree.png') no-repeat center center;
    background-size: cover;
  }
  .circle {
    position: absolute;
    top: 3.57rem;
    left: 0rem;
    width: 7.5rem;
    height: 7.5rem;
    background: url('~@/assets/images/farm/circle.png') no-repeat center center;
    background-size: cover;
  }
  .stars {
    position: absolute;
    top: 3.42rem;
    left: 0.33rem;
    width: 6.85rem;
    height: 6.85rem;
    background: url('~@/assets/images/farm/stars.png') no-repeat center center;
    background-size: cover;
  }

  .desc {
    position: absolute;
    top: 10.56rem;
    left: 0.83rem;
    width: 5.84rem;
    height: 2rem;
    background: url('~@/assets/images/farm/desc.png') no-repeat center center;
    background-size: cover;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 13.22rem;
    left: 1.08rem;
    width: 5.34rem;
    height: 0.94rem;
    background: url('~@/assets/images/farm/button.png') no-repeat center center;
    background-size: cover;
    font-size: 0.32rem;
    font-weight: 600;
    color: #9d4f07;
    a {
      color: #9d4f07;
    }
  }
</style>
