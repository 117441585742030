// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/farm/main-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/farm/tree.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/farm/circle.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/images/farm/stars.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/assets/images/farm/desc.png");
var ___CSS_LOADER_URL_IMPORT_5___ = require("@/assets/images/farm/button.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, ".farm[data-v-15623dca]{position:relative;height:100vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover}.swipe[data-v-15623dca]{position:absolute;top:2.38rem;left:1.65rem;background:rgba(0,31,72,.1);border-radius:.28rem}.my-swipe[data-v-15623dca],.swipe[data-v-15623dca]{width:4.2rem;height:.4rem}.my-swipe[data-v-15623dca]{line-height:.4rem;text-align:center;font-size:.24rem;color:#fff}.tree[data-v-15623dca]{top:3.54rem;left:.34rem;width:6.82rem;height:7.78rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:cover}.circle[data-v-15623dca],.tree[data-v-15623dca]{position:absolute}.circle[data-v-15623dca]{top:3.57rem;left:0;width:7.5rem;height:7.5rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 50%;background-size:cover}.stars[data-v-15623dca]{top:3.42rem;left:.33rem;width:6.85rem;height:6.85rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat 50%;background-size:cover}.desc[data-v-15623dca],.stars[data-v-15623dca]{position:absolute}.desc[data-v-15623dca]{top:10.56rem;left:.83rem;width:5.84rem;height:2rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat 50%;background-size:cover}.button[data-v-15623dca]{display:flex;align-items:center;justify-content:center;position:absolute;top:13.22rem;left:1.08rem;width:5.34rem;height:.94rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") no-repeat 50%;background-size:cover;font-size:.32rem;font-weight:600}.button[data-v-15623dca],.button a[data-v-15623dca]{color:#9d4f07}", ""]);
// Exports
module.exports = exports;
